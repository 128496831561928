import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAnalytics, logEvent } from '@firebase/analytics';
import CustomIcon from '../../../shared/components/CustomIcon';
import Colors from '../../../shared/themes/Colors';
import { appointmentLocalStepsRequest } from '../../../shared/redux/actions/PatientAppointmentActions';
import {
  getPatientAppointmentState,
  getUserProfileState,
} from '../../../shared/redux/src/StatesGetter';
import { isAnyAppointmentInProgress } from '../../../shared/modules/PatientAppointmentUtils';
import Alerts from '../../../shared/components/Alerts';
import { patientLocallyChooseSpecialization } from '../../../shared/redux/actions/DoctorsActions';
import GAService from '../../../shared/services/GAService';
import { requestMediaCamera } from '../../../shared/modules/PermissionsUtils';

const FindADoctorCard = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { specialization, specializations } = props;
  const { t } = useTranslation();
  const userProfile = useSelector(getUserProfileState, shallowEqual);
  const [micAndCamPermission, setMicAndCamPermission] = useState(false);

  const {
    status: appointmentStatus,
    stickyStatus,
    noOfSteps,
  } = useSelector(getPatientAppointmentState, shallowEqual);

  const handleSearchForDoctor = () => {
    localStorage.setItem('PATIENT_CALL_TO_ACTION', 'false');
    requestMediaCamera('patient').then((response) => {
      if (!response) {
        window.location.href = '/support/permissions';
      }

      if (!specialization) {
        GAService.event('web_searchDoctor', { patient: userProfile.email });

        // const analytics = getAnalytics();
        // logEvent(analytics, 'call_specialist_initialized_web', {});

        navigate('/patient/call-specialist/select-specialization');
        dispatch(
          appointmentLocalStepsRequest({
            newAppointment: true,
            noOfSteps: 4,
            status: 'requested',
            type: 'callSpecialist',
          }),
        );
      } else {
        const selectedSpecialization = specializations.data.find(
          (specializationItem) => specializationItem.name === specialization,
        );
        if (isAnyAppointmentInProgress(appointmentStatus, stickyStatus)) {
          Alerts.simpleAlert(t('info'), t('validations.alreadyHasAppointment'));
          return;
        }
        dispatch(patientLocallyChooseSpecialization(selectedSpecialization));
        dispatch(
          appointmentLocalStepsRequest({
            newAppointment: true,
            noOfSteps: 4,
            type: 'callSpecialist',
            specializationId: +selectedSpecialization.id,
            specializationName: selectedSpecialization.name,
          }),
        );
        navigate('/patient/call-specialist/profile');
      }
    });
  };

  return (
    <div className="col-12 colp10">
      <div className="box">
        <div className="medic-card-header find-medic-card-header">
          <div className="avatar" />
        </div>
        <div className="find-medic-card-info">
          <div className="find-medic-card-info-text">
            {t('medicalApp.homeScreenPatient.needADoctorNow')}
          </div>
          <div className="find-medic-card-info-text-details">
            {t('medicalApp.homeScreenPatient.needADoctorNowCallAllDoctors')}
          </div>
        </div>
        <div className="footer-box find-medic-card-footer">
          {specialization &&
            specializations.data.map((specializationItem) => {
              if (specializationItem.name === specialization) {
                return (
                  <div className="d-flex justify-content-between border-top border-bottom mb-20 find-medic-card-footer-text">
                    <div className="subtitle">{t('appointments.appointment')}</div>
                    <div className="price">
                      {specializationItem.callSpecialistPriceAsString} /{' '}
                      {specializationItem.callSpecialistDuration} {t('minutes')}
                    </div>
                  </div>
                );
              }

              return '';
            })}
          <div className="action-buttons">
            <div
              className="call-now-btn medic-action-btn cursor-pointer"
              onClick={handleSearchForDoctor}
            >
              <CustomIcon className="custom-icon" color={Colors.white} size="24" icon="Search" />
              <div className="call-now-btn-text">
                {t('medicalApp.homeScreenPatient.needADoctorNowFindADoctor')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindADoctorCard;
