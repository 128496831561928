import Utils from 'shared/modules/Utils';
import i18next from 'i18next';

export const profileDetailsSelector = (profile) => {
  const resolveFamilyDisease = (disease) => {
    if (!disease || !disease.length) {
      return [];
    }

    return disease.map((d) => {
      return {
        id: d?.id,
        profileId: d.kinship.id,
        profile: d.kinship.translations.find((r) => r.locale === i18next.language)?.name ?? '',
        diseases: d.diseases,
      };
    });
  };

  const resolveKinship = (kinship) => {
    if (!kinship) {
      return undefined;
    }

    const translatedKinship = kinship.translations.find((k) => k.locale === i18next.language);

    if (translatedKinship) {
      return translatedKinship.name;
    }

    return undefined;
  };

  const preparedProfilePhoneNumber = (phoneNumber) => {
    if (phoneNumber?.length === 9) {
      return `+40${phoneNumber}`;
    }

    if (phoneNumber?.length === 10) {
      return `+4${phoneNumber}`;
    }

    return phoneNumber;
  };

  const resolveCountry = (country) => {
    if (!country) {
      return undefined;
    }

    const translatedCountry = country.translations.find((k) => k.locale === i18next.language);

    if (translatedCountry) {
      return translatedCountry.name;
    }

    return undefined;
  };

  return {
    id: profile?.id,
    email: profile.email,
    locale: profile.locale,
    imageUrl: profile.picture_media?.public_url ?? null,
    isMainProfile: profile.is_main_profile,
    accountStatus: profile.account_status,
    fullNameWithTitle: profile.full_name_with_title,
    firstName: profile.first_name,
    lastName: profile.last_name,
    numericPersonalCode: profile.numeric_personal_code,
    rawGender: profile.gender ?? '',
    gender: Utils.solveGender(profile?.gender),
    birthDate: profile.birth_date,
    age: profile.birth_date ? Utils.calculateAge(profile.birth_date) : undefined,
    phoneNumberCountry: profile?.phone_number_country?.calling_code,
    phoneCountryCodeId: profile?.phone_number_country?.id,
    phoneNumber: preparedProfilePhoneNumber(profile.phone_number),
    userLanguages: profile?.user_languages,
    weight: profile.weight,
    height: profile.height,
    bloodSystem: profile.blood_system,
    isSmoker: profile.is_smoker,
    isAlcoholConsumer: profile.is_alcohol_consumer,
    knownAllergies: profile.known_allergies,
    pastMedicalConditions: profile.past_medical_conditions,
    currentMedicalConditions: profile.current_medical_conditions,
    patientKinshipDiseases: resolveFamilyDisease(profile.patient_kinship_diseases),
    patientKinshipId: profile.patient_kinship?.id,
    patientKinshipName: resolveKinship(profile.patient_kinship),
    type: Utils.calculateAge(profile.birth_date) >= 18 ? 'adult' : 'child',
    isChild: Utils.calculateAge(profile.birth_date) >= 18 ? 0 : 0,
    address: {
      addressAsString: profile?.address?.address_as_string,
      city: profile?.address?.city,
      zipCode: profile?.address?.zip_code,
      county: profile?.address?.county,
      department: profile?.address?.department,
      country: resolveCountry(profile?.address?.country),
      line1: profile?.address?.line1,
    },
    isCreatedWithSocialLogin: profile.is_created_with_social_login,
    patientMarketingNotificationSetting: profile?.patient_marketing_notification_setting,
    invoiceAddress: profile?.invoice_address,
    isUnconfirmedFromSocial: profile?.is_unconfirmed_from_social,
    personalCode: profile?.personal_code,
    hasReferrer: profile?.has_referrer,
    hasCompleteAddress: profile?.has_complete_address,
  };
};
