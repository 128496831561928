import 'doctor/styles/wallet.css';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import { fetchIncomeTransactionsRequest } from 'shared/redux/actions/TransactionActions';
import {
  getIncomeTransactionsMonthlyState,
  getIncomeTransactionsState,
} from 'shared/redux/src/StatesGetter';
import {
  downloadPrivateMediaFileRequest,
  getPrivateMediaFileRequest,
} from 'shared/redux/actions/UngroupedActions';
import Colors from 'shared/themes/Colors';
import CustomIcon from 'shared/components/CustomIcon';
import { useLocation } from 'react-router';
import { translatedFullDate } from 'shared/modules/DateTimeUtils';
import { getAppointmentDetailsRequest } from 'shared/redux/actions/DoctorAppointmentsActions';
import Alerts from 'shared/components/Alerts';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { getEnv } from '../../../shared/services/EnvService';

type LocationState = {
  data: Record<string, never>;
  type: string;
};

const DoctorWalletUnfolded = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { data = {}, type = '' } = location?.state as LocationState;
  const dateString = data?.dateString ?? '';
  const dateStart = data?.dateStart ?? dayjs().format('YYYY-MM-DD HH:mm:ss');
  const dateEnd = data?.dateEnd ?? dayjs().format('YYYY-MM-DD HH:mm:ss');

  const transactionsState = useSelector(getIncomeTransactionsState, shallowEqual);

  const transactionMonthlyState = useSelector(getIncomeTransactionsMonthlyState, shallowEqual);

  const { data: transactions, currentPage, noOfPages } = transactionsState;
  const { data: monthly } = transactionMonthlyState;
  const [listItems, setListItems] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const fetchResults = () => {
    dispatch(fetchIncomeTransactionsRequest({ dateStart, dateEnd, limit, page }));
  };

  useEffect(() => {
    const newArray = _.unionBy(listItems, transactions, 'id');
    setListItems(newArray);
  }, []);
  useEffect(() => {
    const newArray = _.unionBy(listItems, transactions, 'id');
    setListItems(newArray);
  }, [transactionsState]);
  useEffect(() => {
    fetchResults();
  }, [page]);
  const loadMore = () => {
    if (currentPage === noOfPages) return;

    const newPage = page + 1;
    setPage(newPage);
  };
  const viewFile = (mediaId, status) => {
    if (status !== 'available') {
      Alerts.simpleAlert(t('error'), t('alerts.fileNotReady'));
      return;
    }
    dispatch(getPrivateMediaFileRequest(mediaId));
  };
  const downloadFile = async (mediaId, status, xmlOriginalFileName) => {
    if (status !== 'available') {
      Alerts.simpleAlert(t('error'), t('alerts.fileNotReady'));
    }

    dispatch(downloadPrivateMediaFileRequest({ mediaId, xmlOriginalFileName }));
  };

  useEffect(() => {}, []);
  const renderItem = (item) => {
    const {
      createdAt = dayjs().format('YYYY-MM-DD HH:mm:ss'),
      amountRefundedAsString = '',
      priceGrossAsString = '',
      incomeAsString = '',
      patientName = '',
      doctorName = '',
      consultationInvoice = null,
      consultationInvoiceId,
      consultationInvoiceStatus = '',
      consultationXMLInvoice = null,
      consultationInvoiceXMLId,
      consultationInvoiceXMLStatus = '',
      xmlOriginalFileName = '',
      platformFeeInvoice = null,
      platformFeeInvoiceId,
      platformFeeInvoiceStatus = '',
      platformFeeAsString = '',
      stornoConsultationInvoice = null,
      stornoConsultationInvoiceId = '',
      stornoConsultationInvoiceStatus = '',
      stornoPlatformFeeInvoice = null,
      stornoPlatformFeeInvoiceId = '',
      stornoPlatformFeeInvoiceStatus = '',
      voucher = '',
      id,
      paymentStatus = '',
    } = item;

    const goToDetails = (e) => {
      e.preventDefault();
      dispatch(getAppointmentDetailsRequest({ id }));
      navigate(`/doctor/consultation-details/${id}`, {
        state: {
          templateType: 'past',
        },
      });
    };

    return (
      <div className="unfolded-box" key={id.toString()}>
        <div className="header cursor-pointer" onClick={goToDetails}>
          <CustomIcon className="custom-icon" color={Colors.darkGrey} size="24" icon="Card" />
          <div className="details">
            <div className="row">
              <div className="col info-text">{t('wallet.initialAppointmentFee')}</div>
              <div className="col-4 text-right amount">{priceGrossAsString}</div>
            </div>
            <div className="row">
              <div className="col info-text">{t('wallet.doctorIncome')}</div>
              <div className="col-4 text-right amount">{incomeAsString}</div>
            </div>
            <div className="row">
              <div className="col info-text">{t('wallet.platformFee')}</div>
              <div className="col-4 text-right amount">{platformFeeAsString}</div>
            </div>
            {voucher && voucher !== '' && (
              <div className="row">
                <div className="col info-text">{t('wallet.voucher')}</div>
                <div className="col-4 text-right amount">{voucher}</div>
              </div>
            )}
            {patientName.length > 1 && <div className="fullName">{patientName}</div>}
            {doctorName && <div className="fullName">{doctorName}</div>}
            <div className="data">{translatedFullDate(createdAt)}</div>
            <div className="row mt-2">
              <div className="col info-text">{t('wallet.status')}</div>
              <div className="col-4 text-right amount">
                {paymentStatus === 'full_refunded' ? t(`wallet.${paymentStatus}`) : paymentStatus}
              </div>
            </div>
          </div>
          <CustomIcon
            className="custom-icon arrow"
            color={Colors.darkGrey}
            size="24"
            icon="Right_pointer"
          />
        </div>

        {amountRefundedAsString !== '' && amountRefundedAsString && (
          <div className="amount-refunded">
            <CustomIcon
              className="custom-icon"
              color={Colors.warningColor}
              size="24"
              icon="Warning_2"
            />
            <div className="text">{t('refundedAmount')}</div>
            <div className="amount">{amountRefundedAsString}</div>
          </div>
        )}

        {consultationInvoice && (
          <>
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                viewFile(consultationInvoiceId, consultationInvoiceStatus);
              }}
              className="white-button download-invoice-btn"
            >
              <CustomIcon
                className="custom-icon"
                color={Colors.medicColor}
                size="24"
                icon="Download"
              />
              {t('downloadInvoiceService')}
            </button>
            {consultationXMLInvoice && (
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  downloadFile(
                    consultationInvoiceXMLId,
                    consultationInvoiceXMLStatus,
                    xmlOriginalFileName,
                  );
                }}
                className="white-button download-invoice-btn"
              >
                <CustomIcon
                  className="custom-icon"
                  color={Colors.medicColor}
                  size="24"
                  icon="Download"
                />
                {t('downloadXMLInvoiceService')}
              </button>
            )}
          </>
        )}
        {platformFeeInvoice && (
          <button
            onClick={(e) => {
              e.preventDefault();
              viewFile(platformFeeInvoiceId, platformFeeInvoiceStatus);
            }}
            type="button"
            className="white-button download-invoice-btn"
          >
            <CustomIcon
              className="custom-icon"
              color={Colors.medicColor}
              size="24"
              icon="Download"
            />
            {t('downloadInvoiceCommission')}
          </button>
        )}
        {stornoConsultationInvoice && (
          <button
            onClick={(e) => {
              e.preventDefault();
              viewFile(stornoConsultationInvoiceId, stornoConsultationInvoiceStatus);
            }}
            type="button"
            className="white-button download-invoice-btn"
          >
            <CustomIcon
              className="custom-icon"
              color={Colors.medicColor}
              size="24"
              icon="Download"
            />
            {t('downloadStornoInvoice')}
          </button>
        )}
        {stornoPlatformFeeInvoice && (
          <button
            onClick={(e) => {
              e.preventDefault();
              viewFile(stornoPlatformFeeInvoiceId, stornoPlatformFeeInvoiceStatus);
            }}
            type="button"
            className="white-button download-invoice-btn"
          >
            <CustomIcon
              className="custom-icon"
              color={Colors.medicColor}
              size="24"
              icon="Download"
            />
            {t('downloadStornoFeeInvoice')}
          </button>
        )}
      </div>
    );
  };

  return (
    <section className="wallet">
      <div className="title-section transactions-data-wrapper">
        <h1>{t('mainScreenRoutes.details')}</h1>
        <div className="small-title-text">{dateString}</div>
      </div>
      <div className="total-box">
        <ListGroup>
          <ListGroup.Item>
            <div className="title">{t('wallet.earningsDoctor')}</div>
            <div className="details">{`${monthly.totalIncome} ${getEnv('CURRENCY')}`}</div>
          </ListGroup.Item>
          <ListGroup.Item>
            <div className="title">{t('wallet.platformFee')}</div>
            <div className="details">{`${monthly.totalPlatformFee} ${getEnv('CURRENCY')}`}</div>
          </ListGroup.Item>
          {monthly.monthlyInvoice && (
            <button
              onClick={(e) => {
                e.preventDefault();
                viewFile(monthly.monthlyInvoiceId, monthly.monthlyInvoiceStatus);
              }}
              type="button"
              className="white-button download-invoice-btn"
            >
              <CustomIcon
                className="custom-icon"
                color={Colors.medicColor}
                size="24"
                icon="Download"
              />
              {t('invoice')}
            </button>
          )}
          {monthly.monthlyReport && (
            <button
              onClick={(e) => {
                e.preventDefault();
                viewFile(monthly.monthlyReportId, monthly.monthlyReportStatus);
              }}
              type="button"
              className="white-button download-invoice-btn"
            >
              <CustomIcon
                className="custom-icon"
                color={Colors.medicColor}
                size="24"
                icon="Download"
              />
              {t('reportPdf')}
            </button>
          )}
        </ListGroup>
      </div>
      {transactions.length > 0 ? (
        <InfiniteScroll
          dataLength={listItems.length}
          next={loadMore}
          hasMore={currentPage !== noOfPages}
          loader={<h4 style={{ textAlign: 'center' }}>Loading...</h4>}
          className="transactions-data-wrapper"
        >
          {listItems.map((transaction) => renderItem(transaction))}
        </InfiniteScroll>
      ) : (
        <div className="empty-container">{t('wallet.noTransactions')}</div>
      )}
    </section>
  );
};

DoctorWalletUnfolded.defaultProps = {
  match: {},
  location: {},
};
DoctorWalletUnfolded.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({}),
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      data: PropTypes.shape({}),
      type: PropTypes.string,
    }),
  }),
};
export default DoctorWalletUnfolded;
