import { actionChannel, call, fork, put, take } from 'redux-saga/effects';
import {
  DOWNLOAD_PRIVATE_MEDIA_FILE_REQUEST,
  GET_PRIVATE_MEDIA_FILE_REQUEST,
} from 'shared/redux/types/UngroupedTypes';
import { getPrivateMediaFileAPI } from 'shared/redux/api/UngroupedApi';
import {
  downloadPrivateMediaFileError,
  downloadPrivateMediaFileSuccess,
  getPrivateMediaFileError,
  getPrivateMediaFileSuccess,
} from 'shared/redux/actions/UngroupedActions';

function* getPrivetMediaFile(actionType) {
  const privateMediaChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(privateMediaChannel);
    try {
      const response = yield call(getPrivateMediaFileAPI, payload);
      window.open(response.data, '_blank');
      yield put(getPrivateMediaFileSuccess({}));
    } catch ({ message }) {
      yield put(getPrivateMediaFileError({ message }));
    }
  }
}

const getFile = async (fileLink, xmlOriginalFileName) => {
  const response = await fetch(fileLink);
  const blob = await response.blob();

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.href = url;
  link.download = xmlOriginalFileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

function* downloadPrivetMediaFile(actionType) {
  const privateMediaChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(privateMediaChannel);
    try {
      const response = yield call(getPrivateMediaFileAPI, payload.mediaId);

      getFile(response.data, payload.xmlOriginalFileName).then();

      yield put(downloadPrivateMediaFileSuccess({}));
    } catch ({ message }) {
      yield put(downloadPrivateMediaFileError({ message }));
    }
  }
}

function* mediaFilesSaga() {
  yield fork(getPrivetMediaFile, GET_PRIVATE_MEDIA_FILE_REQUEST);
  yield fork(downloadPrivetMediaFile, DOWNLOAD_PRIVATE_MEDIA_FILE_REQUEST);
}

export default mediaFilesSaga;
