import 'patient/styles/account.css';
import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Tab from 'react-bootstrap/Tab';
import { Dropdown, Nav } from 'react-bootstrap';
import Colors from 'shared/themes/Colors';
import CustomIcon from 'shared/components/CustomIcon';
import { requestLogout } from 'shared/redux/actions/AuthActions';
import {
  getAuthState,
  getPatientProfileState,
  getVouchersState,
  getPaymentMethodsState,
} from 'shared/redux/src/StatesGetter';
import { fetchPatientProfileRequest } from 'shared/redux/actions/PatientProfileActions';
import {
  getPatientTransactionsRequest,
  getPaymentMethodsRequest,
} from 'shared/redux/actions/PatientPaymentActions';
import { useTranslation } from 'react-i18next';
import { fetchVouchersRequest } from 'shared/redux/actions/VouchersActions';
import { getFavouriteDoctorsRequest } from 'shared/redux/actions/FavouriteDoctorsActions';
import { useLocation } from 'react-router';
import i18next from 'i18next';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { StripeElementLocale } from '@stripe/stripe-js/types/stripe-js/elements-group';
import PatientAccountSettingsBlock from './PatientAccountSettingsBlock';
import PatientAccountFavoriteMedicsBlock from './PatientAccountFavoriteMedicsBlock';
import PatientAccountMyVouchersBlock from './PatientAccountMyVouchersBlock';
import PatientAccountCardsBlock from './PatientAccountCardsBlock';
import PatientAccountTransactionHistoryBlock from './PatientAccountTransactionHistoryBlock';
import PatientAccountChangePasswordBlock from './PatientAccountChangePasswordBlock';
import PatientAccountAssistanceBlock from './PatientAccountAssistanceBlock';
import PatientAccountDeleteAccountBlock from './PatientAccountDeleteAccountBlock';
import PatientAccountPersonalDataBlock from './PatientAccountPersonalDataBlock';
import { getEnv } from '../../../shared/services/EnvService';
import PatientAccountInviteAFriendBlock from './PatientAccountInviteAFriendBlock';
import PatientAccountContactBlock from './PatientAccountContactBlock';

const stripePromise = loadStripe(`${getEnv('STRIPE_PK_KEY')}`);

const PatientAccount = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isProfileCompleted, hasFullAccess, userData } = useSelector(getAuthState, shallowEqual);
  const patientProfile = useSelector(getPatientProfileState, shallowEqual);
  const { patientMarketingNotificationSetting, isCreatedWithSocialLogin } = patientProfile;
  const [marketingNotificationSetting, setMarketingNotificationSetting] = useState(
    patientMarketingNotificationSetting,
  );
  const vouchers = useSelector(getVouchersState, shallowEqual);
  const paymentMethods = useSelector(getPaymentMethodsState, shallowEqual);
  const showPatientProfile = () => dispatch(fetchPatientProfileRequest({}));
  const showFavouriteMedics = () => dispatch(getFavouriteDoctorsRequest({}));
  const showVouchers = () => dispatch(fetchVouchersRequest({}));
  const showPaymentMethods = () => dispatch(getPaymentMethodsRequest({}));
  const showTransactionHistory = () => dispatch(getPatientTransactionsRequest({}));
  const [backDrop, setBackDrop] = useState('');
  const searchName = useLocation().search;
  const section = new URLSearchParams(searchName).get('section');
  const onLogOut = (e) => {
    e.preventDefault();
    dispatch(requestLogout({}));
  };
  const onToggle = (e) => {
    if (e === true) {
      setBackDrop('dropdown-backdrop show');
    } else {
      setBackDrop('');
    }
  };

  useEffect(() => {
    dispatch(fetchPatientProfileRequest({}));
    dispatch(fetchVouchersRequest({}));
  }, []);

  useEffect(() => {
    setMarketingNotificationSetting(patientMarketingNotificationSetting);
  }, [patientMarketingNotificationSetting]);

  const stripeOptions = (language) => {
    return {
      locale: language as StripeElementLocale,
      // fonts: [
      //   {
      //     family: 'Rota',
      //     src: 'url(/fonts/Rota-SemiBold.woff2)',
      //     weight: '600',
      //   },
      // ],
    };
  };
  // Warning: Unsupported prop change on Elements: You cannot change the `stripe` prop after setting it.
  // TODO: Find a way to change the locale on existing stripe instance
  useEffect(() => {
    stripeOptions(i18next.language);
  }, [i18next.language]);

  return (
    <section className="account">
      <Elements stripe={stripePromise} options={stripeOptions(i18next.language)}>
        <div className="title-section">
          <h1>{t('account.account')}</h1>
        </div>
        <div>
          <Tab.Container
            id="account-tabs-menu"
            // defaultActiveKey={hasFullAccess ? 'choose-section' : 'personal-data'}
            defaultActiveKey={
              // eslint-disable-next-line no-nested-ternary
              !section ? (hasFullAccess ? 'choose-section' : 'personal-data') : 'my-vouchers'
            }
          >
            <div className="row rowp10">
              <div className="menu-box colp10">
                <div className="menu-account-mobile">
                  <Dropdown onToggle={onToggle}>
                    <Dropdown.Toggle id="dropdown-basic">
                      <CustomIcon
                        className="custom-icon"
                        color={Colors.darkGrey}
                        size="24"
                        icon="List"
                      />
                      {t('menu')}
                      <CustomIcon
                        className="custom-icon rotate-90"
                        color={Colors.darkGrey}
                        size="24"
                        icon="Right_pointer"
                      />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Nav variant="tabs">
                        <Nav.Item>
                          <Nav.Link eventKey="personal-data" onClick={showPatientProfile}>
                            <div>
                              <div className="title">
                                <div>{t('settings.personalData')}</div>
                                <div className="description">{t('settings.myAccountDetails')}</div>
                              </div>
                              <CustomIcon
                                className="custom-icon"
                                color={Colors.darkGrey}
                                size="28"
                                icon="Profil"
                              />
                            </div>
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                          <Nav.Link eventKey="contact">
                            <div>
                              <div className="title">
                                <div>{t('contact')}</div>
                              </div>
                              <CustomIcon
                                className="custom-icon"
                                color={Colors.darkGrey}
                                size="28"
                                icon="Mail"
                              />
                            </div>
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                          <Nav.Link eventKey="settings">
                            <div>
                              <div className="title">
                                <div>{t('settings.settings')}</div>
                                <div className="description">{t('settings.settingsDetails')}</div>
                              </div>
                              <CustomIcon
                                className="custom-icon"
                                color={Colors.darkGrey}
                                size="28"
                                icon="Settings"
                              />
                            </div>
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                          <Nav.Link eventKey="inviteAFriend">
                            <div>
                              <div className="title">
                                <div>{t('referralInviteAFriend')}</div>
                              </div>
                              <CustomIcon
                                className="custom-icon"
                                color={Colors.darkGrey}
                                size="28"
                                icon="Share"
                              />
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>

                      {hasFullAccess && (
                        <Nav variant="tabs" className="small-nav">
                          <Nav.Item>
                            <Nav.Link eventKey="favorite-medics" onClick={showFavouriteMedics}>
                              <div>
                                <div className="title">
                                  <div>{t('settings.favorites')}</div>
                                </div>
                                <CustomIcon
                                  className="custom-icon"
                                  color={Colors.darkGrey}
                                  size="28"
                                  icon="Heart_Empty"
                                />
                              </div>
                            </Nav.Link>
                          </Nav.Item>

                          <Nav.Item>
                            <Nav.Link eventKey="my-vouchers" onClick={showVouchers}>
                              <div>
                                <div className="title">
                                  <div>
                                    {t('settings.myVouchers')}
                                    {vouchers && <span className="number">{vouchers.length}</span>}
                                  </div>
                                </div>
                                <CustomIcon
                                  className="custom-icon"
                                  color={Colors.darkGrey}
                                  size="28"
                                  icon="Price"
                                />
                              </div>
                            </Nav.Link>
                          </Nav.Item>

                          <Nav.Item>
                            <Nav.Link eventKey="cards" onClick={showPaymentMethods}>
                              <div>
                                <div className="title">
                                  <div>{t('wallet.cards')}</div>
                                </div>
                                <CustomIcon
                                  className="custom-icon"
                                  color={Colors.darkGrey}
                                  size="28"
                                  icon="Cards"
                                />
                              </div>
                            </Nav.Link>
                          </Nav.Item>

                          <Nav.Item>
                            <Nav.Link
                              eventKey="transaction-history"
                              onClick={showTransactionHistory}
                            >
                              <div>
                                <div className="title">
                                  <div>{t('wallet.transactions')}</div>
                                </div>
                                <CustomIcon
                                  className="custom-icon"
                                  color={Colors.darkGrey}
                                  size="28"
                                  icon="Transactions"
                                />
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      )}

                      <Nav variant="tabs" className="small-nav">
                        {!isCreatedWithSocialLogin && (
                          <Nav.Item>
                            <Nav.Link eventKey="change-password">
                              <div>
                                <div className="title">
                                  <div>{t('settings.changePassword')}</div>
                                </div>
                                <CustomIcon
                                  className="custom-icon"
                                  color={Colors.darkGrey}
                                  size="28"
                                  icon="Password"
                                />
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                        )}
                        <Nav.Item>
                          <Nav.Link eventKey="assistance">
                            <div>
                              <div className="title">
                                <div>{t('settings.support')}</div>
                              </div>
                              <CustomIcon
                                className="custom-icon"
                                color={Colors.darkGrey}
                                size="28"
                                icon="Question_2"
                              />
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="delete-account">
                            <div>
                              <div className="title">
                                <div>{t('settings.deleteAccount')}</div>
                              </div>
                              <CustomIcon
                                className="custom-icon"
                                color={Colors.darkGrey}
                                size="28"
                                icon="Delete"
                              />
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link onClick={onLogOut}>
                            <div>
                              <div className="title">
                                <div>{t('settings.logout')}</div>
                              </div>
                              <CustomIcon
                                className="custom-icon"
                                color={Colors.darkGrey}
                                size="28"
                                icon="Logout"
                              />
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Dropdown.Menu>
                  </Dropdown>
                  <div className={backDrop} />
                </div>
                <Nav variant="tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="personal-data" onClick={showPatientProfile}>
                      <div>
                        <div className="title">
                          <div>{t('settings.personalData')}</div>
                          <div className="description">{t('settings.myAccountDetails')}</div>
                        </div>
                        <CustomIcon
                          className="custom-icon"
                          color={Colors.darkGrey}
                          size="28"
                          icon="Profil"
                        />
                      </div>
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link eventKey="contact">
                      <div>
                        <div className="title">
                          <div>{t('contact')}</div>
                        </div>
                        <CustomIcon
                          className="custom-icon"
                          color={Colors.darkGrey}
                          size="28"
                          icon="Mail"
                        />
                      </div>
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link eventKey="inviteAFriend">
                      <div>
                        <div className="title">
                          <div>{t('referralInviteAFriend')}</div>
                        </div>
                        <CustomIcon
                          className="custom-icon"
                          color={Colors.darkGrey}
                          size="28"
                          icon="Share"
                        />
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>

                {hasFullAccess && (
                  <Nav variant="tabs" className="small-nav">
                    <Nav.Item>
                      <Nav.Link eventKey="favorite-medics" onClick={showFavouriteMedics}>
                        <div>
                          <div className="title">
                            <div>{t('settings.favorites')}</div>
                          </div>
                          <CustomIcon
                            className="custom-icon"
                            color={Colors.darkGrey}
                            size="28"
                            icon="Heart_Empty"
                          />
                        </div>
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link eventKey="my-vouchers" onClick={showVouchers}>
                        <div>
                          <div className="title">
                            <div>
                              {t('settings.myVouchers')}
                              {vouchers && <span className="number">{vouchers.length}</span>}
                            </div>
                          </div>
                          <CustomIcon
                            className="custom-icon"
                            color={Colors.darkGrey}
                            size="28"
                            icon="Price"
                          />
                        </div>
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link eventKey="cards" onClick={showPaymentMethods}>
                        <div>
                          <div className="title">
                            <div>{t('wallet.cards')}</div>
                          </div>
                          <CustomIcon
                            className="custom-icon"
                            color={Colors.darkGrey}
                            size="28"
                            icon="Cards"
                          />
                        </div>
                      </Nav.Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Nav.Link eventKey="transaction-history" onClick={showTransactionHistory}>
                        <div>
                          <div className="title">
                            <div>{t('wallet.transactions')}</div>
                          </div>
                          <CustomIcon
                            className="custom-icon"
                            color={Colors.darkGrey}
                            size="28"
                            icon="Transactions"
                          />
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                )}

                <Nav variant="tabs" className="small-nav">
                  {!isCreatedWithSocialLogin && (
                    <Nav.Item>
                      <Nav.Link eventKey="change-password">
                        <div>
                          <div className="title">
                            <div>{t('settings.changePassword')}</div>
                          </div>
                          <CustomIcon
                            className="custom-icon"
                            color={Colors.darkGrey}
                            size="28"
                            icon="Password"
                          />
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  )}
                  <Nav.Item>
                    <Nav.Link eventKey="assistance">
                      <div>
                        <div className="title">
                          <div>{t('settings.support')}</div>
                        </div>
                        <CustomIcon
                          className="custom-icon"
                          color={Colors.darkGrey}
                          size="28"
                          icon="Question_2"
                        />
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="settings">
                      <div>
                        <div className="title">
                          <div>{t('settings.settings')}</div>
                          <div className="description">{t('settings.settingsDetails')}</div>
                        </div>
                        <CustomIcon
                          className="custom-icon"
                          color={Colors.darkGrey}
                          size="28"
                          icon="Settings"
                        />
                      </div>
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link eventKey="delete-account">
                      <div>
                        <div className="title">
                          <div>{t('settings.deleteAccount')}</div>
                        </div>
                        <CustomIcon
                          className="custom-icon"
                          color={Colors.darkGrey}
                          size="28"
                          icon="Delete"
                        />
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link onClick={onLogOut}>
                      <div>
                        <div className="title">
                          <div>{t('settings.logout')}</div>
                        </div>
                        <CustomIcon
                          className="custom-icon"
                          color={Colors.darkGrey}
                          size="28"
                          icon="Logout"
                        />
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>

              <div className="content-box colp10">
                <Tab.Content>
                  <Tab.Pane eventKey="choose-section">
                    <div className="empty-section d-none d-lg-block">
                      {t('settings.chooseSectionMyAccount')}
                    </div>
                    <div className="empty-section d-block d-lg-none">
                      {t('settings.chooseSectionMyAccountTop')}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="personal-data">
                    <PatientAccountPersonalDataBlock profile={patientProfile} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="settings">
                    <PatientAccountSettingsBlock
                      marketingNotification={marketingNotificationSetting}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="inviteAFriend">
                    <PatientAccountInviteAFriendBlock invitation={patientProfile.personalCode} />
                  </Tab.Pane>
                  {hasFullAccess && (
                    <>
                      <Tab.Pane eventKey="favorite-medics">
                        <PatientAccountFavoriteMedicsBlock />
                      </Tab.Pane>
                      <Tab.Pane eventKey="my-vouchers">
                        <PatientAccountMyVouchersBlock vouchers={vouchers} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="cards">
                        <PatientAccountCardsBlock paymentMethods={paymentMethods} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="transaction-history">
                        <PatientAccountTransactionHistoryBlock />
                      </Tab.Pane>
                    </>
                  )}
                  {!isCreatedWithSocialLogin && (
                    <Tab.Pane eventKey="change-password">
                      <PatientAccountChangePasswordBlock />
                    </Tab.Pane>
                  )}
                  <Tab.Pane eventKey="assistance">
                    <PatientAccountAssistanceBlock />
                  </Tab.Pane>
                  <Tab.Pane eventKey="contact">
                    <PatientAccountContactBlock />
                  </Tab.Pane>
                  <Tab.Pane eventKey="delete-account">
                    <PatientAccountDeleteAccountBlock
                      isCreatedWithSocialLogin={patientProfile.isCreatedWithSocialLogin}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </div>
      </Elements>
    </section>
  );
};

export default PatientAccount;
