import { actionChannel, ActionPattern, call, fork, put, take } from 'redux-saga/effects';
import i18next from 'i18next';
import { Action } from 'redux';
import { REGISTER_REQUEST, UPDATE_DEVICE_REG_ID_REQUEST } from 'shared/redux/types/UserTypes';
import { registerAPI, UpdateDeviceRegIdAPI } from 'shared/redux/api/UserApi';
import {
  registerError,
  registerSuccess,
  updateDeviceRegIdError,
  updateDeviceRegIdSuccess,
} from 'shared/redux/actions/UserActions';
import Alerts from 'shared/components/Alerts';
import NavigationService from 'shared/services/NavigationService';
import firebase from 'firebase';
import { getEnv } from 'shared/services/EnvService';
import { getAnalytics, logEvent } from '@firebase/analytics';

function* register(actionType: ActionPattern<Action<any>>) {
  // @ts-ignore
  const registerChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(registerChannel);
    try {
      // const webTokenResponse = yield call(firebase.getWebToken);
      // console.log('deviceRegistrationId', webTokenResponse.deviceRegId);
      // const deviceRegistrationId = webTokenResponse.deviceRegId;

      // const mediaPermission = yield call(requestMediaCamera);
      // if (!deviceRegistrationId) {
      //   throw new Error(i18next.t('pleaseAcceptPermissions'));
      // }
      // if (!mediaPermission) {
      //   throw new Error(i18next.t('toContinuePleaseConnect'));
      // }
      const response = yield call(registerAPI, { ...payload });
      yield put(registerSuccess(response.data.user));

      // const analytics = getAnalytics();
      // logEvent(analytics, 'account_registration_web', {});

      NavigationService.navigate(
        getEnv('APP_TYPE', true) === 'patient' ? '/patient/login' : '/doctor/login',
      );
      Alerts.simpleAlert(`${i18next.t('success')}`, `${i18next.t('alerts.registerCheck')}`);
    } catch ({ message }) {
      yield put(registerError({ message }));
    }
  }
}

function* updateDeviceRegId(actionType: ActionPattern<Action<any>>) {
  // @ts-ignore
  const deviceRegIdChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(deviceRegIdChannel);
    try {
      yield call(UpdateDeviceRegIdAPI, { deviceRegistrationId: payload });
      yield put(updateDeviceRegIdSuccess({}));
    } catch ({ message }) {
      yield put(updateDeviceRegIdError({ message }));
    }
  }
}

function* userSaga() {
  yield fork(register, REGISTER_REQUEST);
  yield fork(updateDeviceRegId, UPDATE_DEVICE_REG_ID_REQUEST);
}

export default userSaga;
