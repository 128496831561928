import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Alert, Modal } from 'react-bootstrap';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { useDispatch } from 'react-redux';
import CustomIcon from '../../shared/components/CustomIcon';
import Colors from '../../shared/themes/Colors';
import { getEnv } from '../../shared/services/EnvService';
import { styles } from '../../shared/settings/GooglePlacesAutocomplete';
import {
  fetchPatientProfileRequest,
  patchPatientProfileRequest,
} from '../../shared/redux/actions/PatientProfileActions';
import Alerts from '../../shared/components/Alerts';

const PaymentInvoicingAddress = (props) => {
  const { hasCompleteAddress, address, patientId, location = '' } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  let currentCity = address.zipCode;

  const [district, setDistrict] = useState(address.department);
  const [city, setCity] = useState(address.city);
  const [county, setCounty] = useState(address.county);
  const [zipCode, setZipCode] = useState(address.zipCode);
  const [street, setStreet] = useState(address.addressAsString);
  const [streetNumber, setStreetNumber] = useState('');
  const [country, setCountry] = useState('');
  const [showModalInvoicingAddress, setShowModalInvoicingAddress] = useState(false);
  const [showGPA, setShowGPA] = useState(true);
  const modalInvoicingAddressClose = () => setShowModalInvoicingAddress(false);

  const saveInvoicingAddress = () => {
    if (!country || !city || !street) {
      Alerts.errorAlert(t('allFieldsMandatory'));
      return;
    }
    if (city === 'București' || city === 'Bucuresti') {
      if (!district) {
        Alerts.errorAlert(t('allFieldsMandatory'));
        return;
      }
    } else if (!county) {
      Alerts.errorAlert(t('allFieldsMandatory'));
      return;
    }
    const dataToUpdate = {
      country,
      county,
      city,
      line1: streetNumber !== '' ? `${street} ${streetNumber}` : street,
      department: district,
      zipCode,
    };

    dispatch(
      patchPatientProfileRequest({
        id: patientId,
        toUpdate: dataToUpdate,
      }),
    );
    setShowModalInvoicingAddress(false);
    setTimeout(() => {
      dispatch(fetchPatientProfileRequest({}));
    }, 1000);
  };

  const updateAddressFields = (addressObject) => {
    const addressComponents = addressObject[0].address_components;
    setStreet('');
    addressComponents.forEach((addressComponent) => {
      if (addressComponent.types.includes('street_number')) {
        setStreetNumber(addressComponent.long_name);
      }

      if (addressComponent.types.includes('route')) {
        setStreet(addressComponent.long_name);
      }

      if (addressComponent.types.includes('locality')) {
        setCity(addressComponent.long_name);
        currentCity = addressComponent.long_name;
      }

      let shouldSkipSL = false;
      [1, 2, 3, 4, 5].forEach((sl) => {
        if (shouldSkipSL) {
          return;
        }
        if (addressComponent.types.includes(`sublocality_level_${sl}`)) {
          setDistrict(addressComponent.long_name);
          shouldSkipSL = true;
        }
      });

      let shouldSkipAAL = false;
      [1, 2, 3, 4, 5, 6, 7].forEach((aal) => {
        if (shouldSkipAAL) {
          return;
        }

        if (addressComponent.types.includes(`administrative_area_level_${aal}`)) {
          if (addressComponent.long_name !== '') {
            if (currentCity === 'București') {
              setCounty('București');
            } else {
              setCounty(addressComponent.long_name);
              setDistrict('');
            }
            shouldSkipAAL = true;
          }
        }
      });

      if (addressComponent.types.includes('postal_code')) {
        setZipCode(addressComponent.long_name);
      }

      if (addressComponent.types.includes('country')) {
        setCountry(addressComponent.long_name);
      }
      setShowGPA(false);
      setTimeout(() => {
        setShowGPA(true);
      }, 200);
    });
  };

  return (
    <>
      {hasCompleteAddress ? (
        <div className="invoicing-address">
          <div className="subtitle">{t('appointments.invoicingAddress')}</div>
          <div
            className={`white-box ${
              location === 'account' || location === 'profile' ? 'border' : ''
            }`}
            onClick={() => {
              setShowModalInvoicingAddress(true);
            }}
          >
            <div className="invoicing-address-content">{address.addressAsString}</div>
            <CustomIcon
              className="custom-icon invoicing-address-edit"
              color={Colors.medicColor}
              size="24"
              icon="Edit"
            />
          </div>
        </div>
      ) : (
        <div className="invoicing-address">
          <div className="subtitle">{t('appointments.invoicingAddress')}</div>
          <div
            className="white-box"
            onClick={() => {
              setShowModalInvoicingAddress(true);
            }}
          >
            <div className="invoicing-address-content">
              <div style={{ textAlign: 'center' }}>
                <CustomIcon className="custom-icon" color={Colors.darkGrey} size="24" icon="Plus" />
                {t('appointments.invoicingAddAddress')}
              </div>
              <div className="invoicing-addAddress-info">
                {t('appointments.invoicingAddAddressInfo')}
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        show={showModalInvoicingAddress}
        onHide={modalInvoicingAddressClose}
        animation
        centered
        className="modal-voucher"
      >
        <Modal.Header closeButton closeLabel={t('close')}>
          <Modal.Title>{t('appointments.invoicingAddress')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="address-google-places-autocomplete">
            {showGPA && (
              <GooglePlacesAutocomplete
                apiKey={getEnv('GOOGLE_PLACES_API_KEY')}
                minLengthAutocomplete={3}
                selectProps={{
                  placeholder: `${t('street')}*`,
                  defaultInputValue: `${street} ${streetNumber}`,
                  onChange: (o) => {
                    geocodeByPlaceId(o.value.place_id)
                      .then((results) => {
                        updateAddressFields(results);
                      })
                      .catch((error) => console.error(error));
                  },
                  styles,
                }}
              />
            )}
          </div>
          {(city === 'București' || city === 'Bucuresti') && (
            <div className="form-input">
              <input
                autoComplete="off"
                id="district"
                name="district"
                type="text"
                className="form-control invoicing-address-input"
                placeholder=" "
                value={district}
                onChange={(e) => {
                  setDistrict(e.target.value);
                }}
              />
              <label htmlFor="district">{t('appointments.district')}*</label>
            </div>
          )}

          <div className="form-input">
            <input
              autoComplete="off"
              id="city"
              name="city"
              type="text"
              className="form-control invoicing-address-input"
              placeholder=" "
              value={city}
              onChange={(e) => {
                setCity(e.target.value);
              }}
            />
            <label htmlFor="city">{t('appointments.city')}*</label>
          </div>

          {city !== 'București' && (
            <div className="form-input">
              <input
                autoComplete="off"
                id="county"
                name="county"
                type="text"
                className="form-control invoicing-address-input"
                placeholder=" "
                value={county}
                onChange={(e) => {
                  setCounty(e.target.value);
                }}
              />
              <label htmlFor="county">{t('appointments.county')}*</label>
            </div>
          )}

          <div className="form-input">
            <input
              autoComplete="off"
              id="zipCode"
              name="zipCode"
              type="text"
              className="form-control invoicing-address-input"
              value={zipCode}
              onChange={(e) => {
                setZipCode(e.target.value);
              }}
              placeholder=" "
            />
            <label htmlFor="zipCode">{t('appointments.zipCode')}</label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="orange-button" onClick={() => saveInvoicingAddress()}>
            {t('appointments.saveInvoicingAddress')}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PaymentInvoicingAddress;
