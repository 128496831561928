import { actionChannel, call, fork, put, select, take } from 'redux-saga/effects';
import { paymentMethodsSelector } from 'shared/redux/selector/PaymentMethodSelector';
import {
  appointmentPaymentAPI,
  attachPaymentMethodToCustomerPI,
  confirmStripePaymentWithPaymentIdApi,
  confirmStripePaymentWithTokenApi,
  createPaymentMethodPI,
  getPatientTransactionsAPI,
  getPaymentMethodsAPI,
  removePaymentMethodPI,
} from 'shared/redux/api/PatientPaymentApi';
import {
  appointmentPaymentError,
  appointmentPaymentRequest,
  appointmentPaymentSuccess,
  confirmPaymentError,
  confirmPaymentRequest,
  confirmPaymentSuccess,
  createPaymentError,
  createPaymentMethodError,
  createPaymentMethodSuccess,
  createPaymentSuccess,
  getPatientTransactionsError,
  getPatientTransactionsSuccess,
  getPaymentMethodsError,
  getPaymentMethodsRequest,
  getPaymentMethodsSuccess,
  removePaymentMethodError,
  removePaymentMethodSuccess,
  savePaymentMethodError,
  savePaymentMethodSuccess,
} from 'shared/redux/actions/PatientPaymentActions';
import {
  APPOINTMENT_PAYMENT_REQUEST,
  CONFIRM_PAYMENT_REQUEST,
  CREATE_PAYMENT_METHOD_REQUEST,
  SAVE_PAYMENT_METHOD_REQUEST,
  CREATE_PAYMENT_REQUEST,
  GET_PATIENT_TRANSACTIONS_REQUEST,
  GET_PAYMENT_METHODS_REQUEST,
  REMOVE_PAYMENT_METHOD_REQUEST,
} from 'shared/redux/types/PatientPaymentTypes';
import {
  getPatientAppointmentState,
  getPaymentMethodsState,
  getPaymentState,
} from 'shared/redux/src/StatesGetter';
import transactionsSelector from 'shared/redux/selector/TransactionsSelector';
import NavigationService from 'shared/services/NavigationService';
import Alerts from 'shared/components/Alerts';
import { appointmentLocalStepsRequest } from 'shared/redux/actions/PatientAppointmentActions';
import i18next from 'i18next';
import _ from 'lodash';
import { getAnalytics, logEvent } from '@firebase/analytics';

function* getPaymentMethods(actionType) {
  const paymentMethodsChannel = yield actionChannel(actionType);
  while (true) {
    yield take(paymentMethodsChannel);
    try {
      const response = yield call(getPaymentMethodsAPI);
      yield put(getPaymentMethodsSuccess(paymentMethodsSelector(response)));
    } catch ({ message }) {
      yield put(getPaymentMethodsError({ message }));
    }
  }
}

function* createPayment(actionType) {
  const createPaymentChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(createPaymentChannel);
    const payloadWithoutPromise = _.omit(payload, ['confirmPayment']);
    try {
      yield put(createPaymentSuccess({ ...payloadWithoutPromise }));
      yield put(getPaymentMethodsRequest({}));

      // const analytics = getAnalytics();
      // logEvent(analytics, 'payment_confirmed_web', {});

      yield put(
        appointmentPaymentRequest({
          appointmentId: payload.appointmentId,
          confirmPayment: payload.confirmPayment,
        }),
      );
    } catch ({ message }) {
      yield put(createPaymentError({ message }));
    }
  }
}

function* appointmentPayment(actionType) {
  const appointmentPaymentChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(appointmentPaymentChannel);
    try {
      const { price } = yield select(getPatientAppointmentState);
      const priceNumber = parseInt(price.split(' ')[0], 10);
      const intentResponse = yield call(appointmentPaymentAPI, {
        appointmentId: payload.appointmentId,
      });
      const { clientSecret } = intentResponse.data;
      if (priceNumber > 2) {
        yield put(confirmPaymentRequest({ confirmPayment: payload.confirmPayment, clientSecret }));
      } else {
        yield put(
          confirmPaymentRequest({
            confirmPayment: payload.confirmPayment,
            clientSecret: null,
            zeroPayment: true,
          }),
        );
      }
      yield put(appointmentPaymentSuccess(intentResponse));
    } catch ({ status, message }) {
      if (status === 403) {
        yield put(appointmentLocalStepsRequest({ newAppointment: true }));
        NavigationService.navigate('/');
      }

      yield put(appointmentPaymentError({ message }));
    }
  }
}

function* confirmPayment(actionType) {
  const confirmPaymentChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(confirmPaymentChannel);
    try {
      const { paymentType, saveCard, paymentMethodId, card } = yield select(getPaymentState);
      const { type: appointmentType } = yield select(getPatientAppointmentState);
      let paymentResponse;
      if (paymentType === 'Card' && !payload.zeroPayment) {
        paymentResponse = yield call(confirmStripePaymentWithTokenApi, {
          ...payload,
          card,
          paymentMethodId,
          saveCard,
        });
      } else if (!payload.zeroPayment) {
        paymentResponse = yield call(confirmStripePaymentWithPaymentIdApi, {
          ...payload,
          paymentMethodId,
        });
      }
      if (paymentResponse?.status === 'requires_action') {
        yield put(confirmPaymentSuccess({ id: paymentResponse?.id, status: 'AuthRequired' }));
      } else {
        if (appointmentType === 'programmed') {
          NavigationService.navigate('/patient/appointment/confirmed-appointment');
        } else if (appointmentType === 'callSpecialist') {
          yield put(
            appointmentLocalStepsRequest({
              stickyStatus: 'WaitForCallSpecialistDoctor',
            }),
          );
          NavigationService.navigate('/patient/call-specialist/waiting-screen');
          Alerts.actionAlert(
            `${i18next.t('success')}`,
            `${i18next.t('appointments.paymentSuccess')}`,
            'Ok',
            () => {},
          );
        } else {
          yield put(
            appointmentLocalStepsRequest({
              stickyStatus: 'SearchDoctor',
            }),
          );
          NavigationService.navigate('/patient/appointment/waiting-screen');
          Alerts.actionAlert(
            `${i18next.t('success')}`,
            `${i18next.t('appointments.paymentSuccess')}`,
            'Ok',
            () => {},
          );
        }
        yield put(confirmPaymentSuccess({ id: paymentResponse?.id, status: 'Confirmed' }));
      }
    } catch ({ message }) {
      yield put(confirmPaymentError({ message }));
    }
  }
}

// We use stripe library in order to create a payment method
function* createPaymentMethod(actionType) {
  const createPaymentMethodChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(createPaymentMethodChannel);
    try {
      const firstResponse = yield call(createPaymentMethodPI, payload);
      yield call(attachPaymentMethodToCustomerPI, {
        paymentMethodId: firstResponse?.id,
      });
      yield put(createPaymentMethodSuccess(firstResponse));
    } catch ({ message }) {
      yield put(createPaymentMethodError({ message }));
    }
  }
}

function* savePaymentMethod(actionType) {
  const savePaymentMethodChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(savePaymentMethodChannel);
    try {
      yield call(attachPaymentMethodToCustomerPI, {
        paymentMethodId: payload.forRequest?.id,
      });
      yield put(savePaymentMethodSuccess(payload.forRequest));
    } catch ({ message }) {
      yield put(savePaymentMethodError({ message }));
    }
  }
}

async function deleteCard(allCards, uniqueId) {
  console.log(allCards);
  const doRemove = async (card) => removePaymentMethodPI({ paymentMethodId: card?.id });
  await Promise.all(
    allCards.map(async (card) => {
      if (card.uniqueId === uniqueId) {
        await doRemove(card);
      }
    }),
  );
}

function* removePaymentMethod(actionType) {
  const removePaymentMethodChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(removePaymentMethodChannel);
    const { all: allCards } = yield select(getPaymentMethodsState);
    try {
      yield call(deleteCard, allCards, payload.uniqueId);
      yield put(removePaymentMethodSuccess(payload.uniqueId));
    } catch ({ message }) {
      yield put(removePaymentMethodError({ message }));
    }
  }
}

function* getPatientTransactions(actionType) {
  const patientTransactionsChannel = yield actionChannel(actionType);
  while (true) {
    const { payload } = yield take(patientTransactionsChannel);
    try {
      const safeAction = {
        limit: 5,
        page: 1,
        ...payload,
      };
      const response = yield call(getPatientTransactionsAPI, safeAction);
      yield put(
        getPatientTransactionsSuccess({
          data: transactionsSelector(response),
          currentPage: response.data.page,
          noOfPages: response.data.pages,
          nextPage: payload.nextPage,
        }),
      );
    } catch ({ message }) {
      yield put(getPatientTransactionsError({ message }));
    }
  }
}

function* patientPaymentSaga() {
  yield fork(getPaymentMethods, GET_PAYMENT_METHODS_REQUEST);
  yield fork(createPayment, CREATE_PAYMENT_REQUEST);
  yield fork(confirmPayment, CONFIRM_PAYMENT_REQUEST);
  yield fork(appointmentPayment, APPOINTMENT_PAYMENT_REQUEST);
  yield fork(createPaymentMethod, CREATE_PAYMENT_METHOD_REQUEST);
  yield fork(savePaymentMethod, SAVE_PAYMENT_METHOD_REQUEST);
  yield fork(removePaymentMethod, REMOVE_PAYMENT_METHOD_REQUEST);
  yield fork(getPatientTransactions, GET_PATIENT_TRANSACTIONS_REQUEST);
}

export default patientPaymentSaga;
