export const UPDATE_SHARED_STATE_REQUEST = 'UPDATE_SHARED_STATE_REQUEST';

export const UPDATE_LOCALE_REQUEST = 'UPDATE_LOCALE_REQUEST';
export const UPDATE_LOCALE_SUCCESS = 'UPDATE_LOCALE_SUCCESS';
export const UPDATE_LOCALE_ERROR = 'UPDATE_LOCALE_ERROR';

export const GET_PRIVATE_MEDIA_FILE_REQUEST = 'GET_PRIVATE_MEDIA_FILE_REQUEST';
export const GET_PRIVATE_MEDIA_FILE_SUCCESS = 'GET_PRIVATE_MEDIA_FILE_SUCCESS';
export const GET_PRIVATE_MEDIA_FILE_ERROR = 'GET_PRIVATE_MEDIA_FILE_ERROR';

export const DOWNLOAD_PRIVATE_MEDIA_FILE_REQUEST = 'DOWNLOAD_PRIVATE_MEDIA_FILE_REQUEST';
export const DOWNLOAD_PRIVATE_MEDIA_FILE_SUCCESS = 'DOWNLOAD_PRIVATE_MEDIA_FILE_SUCCESS';
export const DOWNLOAD_PRIVATE_MEDIA_FILE_ERROR = 'DOWNLOAD_PRIVATE_MEDIA_FILE_ERROR';
